import React from "react"

// import logoDark from "../images/logo/pntOS_Logo_Gradient_Dark_Horizontal.svg"
import logoLight from "../images/logo/pntOs_Logo_Gradient_Light_Vertical.svg"
import ImageRow from "./image-row"
import Button from "../components/button"

const Content = () => (
  <div style={{ textAlign: "center" }}>
    <img src={logoLight} alt="pntOS" className="fade-in-move-up"
      style={{
        maxWidth: "600px",
        zIndex: 100
      }} />
    <h2 className="fade-in-move-up"
      style={{animationDelay: ".5s"}}>Open. Modular. Pluggable.</h2>
    <ImageRow></ImageRow>

    <div className="midContianer fade-in-move-up" style={{animationDelay:"2.5s"}}>
      <div className="content">
        <p>pntOS is an open source, government-owned plugin architecture for building integrated PNT
          sensor fusion applications for all operational environments. Designed for cost, schedule,
          and performance efficiencies, pntOS features standard message formats that make all plugins
           - GOTS, community shared, and proprietary - individually swappable without the need for
           modification. Custom plugins can be developed using any programming language and made
           available to the pntOS community or used for proprietary applications without risk of
           disclosure.
        </p>
      </div>
    <div style={{display: "flex", justifyContent:"center"}}>
      <div className="login">
        <h3>Access pntOS</h3>
        <div>
          <form action="https://pntos.pages.aspn.us/welcome/">
          <Button>Login</Button>
          </form>
        </div>
        <div style={{padding: "1rem"}}>
          <h4>Don't have an account?</h4>
          <p style={{marginBottom: "0"}}>
            Begin the process to request access <a href="./devAccess">here.</a>
          </p>
        </div>
      </div>
    </div>
    </div>
  </div>
)

export default Content
