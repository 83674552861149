import React from "react"

import modular2 from "../images/branding/pntOS_Branding_Elements_Hexagon_22.svg"
import modular from "../images/branding/pntOS_Branding_Elements_Hexagon_24.svg"
import govOwned from "../images/branding/pntOS_Branding_Elements_Icon_8.svg"
import openSrc from "../images/branding/pntOS_Branding_Elements_Icon_7.svg"
import gots from "../images/branding/pntOS_Branding_Elements_Icon_6.svg"

const ImageRow = () => (
    <div className="hexrow" style={{
        padding: "1rem",
        textAlign: "center",
        display: "flex",
        flexDirection: "row",
        flexFlow: "row wrap",
        alignItems: "stretch",
        }}>
        <div className="fade-in-move-up" style={{
            position: "relative",
            margin:"1rem",
            maxWidth:"200px",
            animationDelay: "1s"
            }}  >
            <img src={modular} alt="open" style={{width:"200px"}} />
            <div style={{
                        display: "block",
                        position:"absolute",
                        height: "100%",
                        width: "100%",
                        top: "0",
                        left: "0",
                        right: "0",
                        margin: "auto"}}>
                <img src={govOwned} alt="government owned"style={{maxWidth:"100px",}} />
                <p className="hex">Government-Owned, Community Adopted</p>
            </div>
        </div>
        <div className="fade-in-move-up" style={{
            position: "relative",
            margin:"1rem",
            maxWidth:"200px",
            animationDelay: "1.5s"
            }}  >
            <img src={modular2} alt="modular" style={{width:"200px", position:"relative"}} />
            <div style={{
                        display: "block",
                        position:"absolute",
                        height: "100%",
                        width: "100%",
                        top: "0",
                        left: "0",
                        right: "0",
                        margin: "auto"}}>
                <img src={openSrc} alt="government owned"style={{maxWidth:"100px",}} />
                <p className="hex">Open Source</p>
            </div>
        </div>
        <div className="fade-in-move-up" style={{
            position: "relative",
            margin:"1rem",
            maxWidth:"200px",
            animationDelay: "2s"
            }}  >
            <img src={modular} alt="pluggable" style={{width:"200px", position:"relative"}}/>
            <div style={{
                        display: "block",
                        position:"absolute",
                        height: "100%",
                        width: "100%",
                        top: "0",
                        left: "0",
                        right: "0",
                        margin: "auto"}}>
                <img src={gots} alt="government owned"style={{maxWidth:"100px",}} />
                <p className="hex">GOTS, Community, and Proprietary Plugins</p>
            </div>
        </div>
    </div>
  )

  export default ImageRow
