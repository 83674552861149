import React from "react"

import Button from "../components/button"

const CallToAction = () => (
  <div className="fade-in-move-up"
    style={{ padding: "2rem", textAlign: "center", animationDelay: "2.5s" }}>
    <h2 className="cta">Looking for more info?</h2>
    <form action="mailto:about@pntos.com">
      <Button>Contact Us</Button> or e-mail <tt>about@pntos.com</tt>
    </form>
  </div>
)

export default CallToAction
